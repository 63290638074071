const Routes = [
    {
        name: "Shares",
        path: ["/shares"],
        elementPath: "Shares",
        prefix: "/admin",
        auth: true
    },
    {
        name: "Shares",
        path: ["/shares/:token/edit"],
        elementPath: "Form",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;