const Routes = [
    {
        name: "Dynamic",
        path: ["/dynamic/:key"],
        elementPath: "List",
        prefix: "/admin",
        auth: true
    },
    {
        name: "Dynamic",
        path: ["/dynamic/:key/:id/edit", "/dynamic/:key/create"],
        elementPath: "Edit",
        prefix: "/admin",
        auth: true
    }
]   

export default Routes;