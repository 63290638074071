const Routes = [
    {
        name: "KCCL",
        path: ["/registration"],
        elementPath: "index",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;