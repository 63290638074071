import { createSlice } from '@reduxjs/toolkit'
export const slice = createSlice({
  name: 'common-module',
  initialState: {
    menus: []
  },
  reducers: {
    setMenus: (state, action) => {
      state.menus = action.payload;
      return state;
    }
  },
  extraReducers: { }
})
export const { setMenus } = slice.actions;
export default slice.reducer;