import React, { useEffect } from "react";
import GenerateRoutes from "./Core/Services/Routes";
import SwapSideBar from "./Layouts/Admin/SwapSideBar";
import "./App.css";
import { useDispatch } from "react-redux";
// import { getMenus } from "./Reducer/Actions";

function App() {

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(getMenus())
    }, [dispatch])

    return (
        <>
            <GenerateRoutes adminLayouts={SwapSideBar} />
        </>
    );
    
}

export default App;