const Routes = [
    {
        name: "Membership",
        path: ["/memberships"],
        elementPath: "Membership",
        prefix: "/admin",
        auth: true
    },
    {
        name: "Membership",
        path: ["/memberships/:token/edit"],
        elementPath: "Form",
        prefix: "/admin",
        auth: true
    },
    {
        name: "Membership",
        path: ["/memberships/create"],
        elementPath: "ManageForm",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;