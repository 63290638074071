import Auth from './Auth';
import Dashboard from './Dashboard';
import Membership from './Membership';
import Dynamic from './Dynamic';
import Shares from './Shares';
import Settings from './Settings';
import Users from './Users';
import Reports from './Reports';
import Registration from './Registration';
import EventRegistration from './EventRegistration';

const Modules = {
    Auth, Dashboard, Membership, Dynamic, Shares, Settings, Users, Reports, Registration, EventRegistration
}

export {
    Modules
}