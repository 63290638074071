import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { getColors, getConfig, getLastUrlSegment, getMenus, splitCamelCaseWithAbbreviations } from '../../Core/Utilities';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse, IconButton, SwipeableDrawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import AdjustIcon from '@mui/icons-material/Adjust';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { getAuthUser } from '../../Core/Services/Auth';
import useRedirect from '../../Core/Hooks/Redirect';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AppSnackBar from '../../Components/SnackBar';

export default function ClippedDrawer({children}) {

    const location = useLocation();
    const navigate = useNavigate();
    const { redirectTo } = useRedirect();

    const { menus } = useSelector(state => ({
        menus: state.common.menus
    }))

    const generateTitle = React.useCallback(() => {
        let menus = getMenus();
        console.log(menus)
        menus = menus.find(i => i.path === "/" + getLastUrlSegment())
        document.title = getConfig('APP_NAME') + ' | ' + (menus?.label ?? "")
    }, [])

    React.useEffect(() => {
        setTimeout(generateTitle)
    }, [generateTitle])

    const [menusExpand, setMenusExpand] = React.useState([])

    const urlMatching = (url) => {
        try {
            let splitUrl = location.pathname.split('/');
            url = url.replace('/', '')
            return splitUrl.includes(url);
        } catch (error) {
            
        }
    }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });   

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar variant="dense" position="fixed">
                    {/* <Typography variant="h6" noWrap component="div">
                        {getConfig('APP_NAME')}
                    </Typography> */}
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={event => setState(p => ({...p, left: !state.left}))}
                        >
                            {state.left ? <CloseIcon /> : <MenuIcon /> }
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {getConfig('APP_NAME')} | <span style={{textTransform: 'capitalize'}}>{getAuthUser('role')}</span>, <span style={{textTransform: 'capitalize'}}>{getAuthUser('name')}</span>
                        </Typography>
                    </Toolbar>
            </AppBar>
            <AppSnackBar/>
            <SwipeableDrawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                <Toolbar />
                <div style={{width: 250}}>
                    <List>
                        {getMenus().map((value, index) => {

                            if(value.type === 'section') return <div key={index} style={{ paddingLeft: 18, marginTop: 10, marginBottom: 10, color: '#a5a5a5' }}>{value.label}</div>

                            return (
                                <React.Fragment key={index}>
                                    <ListItem style={{background: urlMatching(value.path) ? getColors('#f3f3f3') : getColors('white')}} onClick={event => {
                                        if(value.key !== 'expand') {
                                            if(value?.onClick) value?.onClick({ value, redirectTo });

                                            if(value.path !== "#") {
                                                navigate(getConfig('DOMAIN_PREFIX') + '' + value.path);
                                                document.title = getConfig('APP_NAME') + ' | ' + (value.label ?? "")
                                            }

                                            setState(p => ({...p, left: false}))
                                            return;
                                        }

                                        setMenusExpand({[index]: !menusExpand[index]})
                                        
                                    }} disablePadding>
                                        <ListItemButton>
                                            <span style={{marginRight: 10}}>{value.icon ?? <AdjustIcon />}</span> <ListItemText style={{fontWeight: 500}} primary={value.label}/>
                                        </ListItemButton>
                                    </ListItem>
                                    {value.key === 'expand' ? <div><Collapse in={Boolean(menusExpand[index])}>
                                        {menus.map((value2, index2) => (
                                            <ListItem key={index2} style={{paddingLeft: 10, textTransform: 'capitalize', background: urlMatching(value2.key) ? getColors('#cccccc') : getColors('#ededed')}} onClick={event => {
                                                navigate(getConfig('DOMAIN_PREFIX') + '/dynamic/' + value2.key);
                                                setState(p => ({...p, left: false}))
                                            }} disablePadding>
                                                <ListItemButton>
                                                    <ArrowRightAltIcon /> <ListItemText primary={splitCamelCaseWithAbbreviations(value2.label, ' ')} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}    
                                    </Collapse></div> : null}
                                </React.Fragment>
                            )
                        })}
                    </List>
                </div>
            </SwipeableDrawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar/>
                <div>{children}</div>
            </Box>
        </Box>
    );
}
