import { createSlice } from '@reduxjs/toolkit'
export const counterSlice = createSlice({
  name: 'counter',
  initialState: { 
    data: []
  },
  reducers: {

    setClientData: (state, actions) => {
      state.data = [...state.data, actions.payload]
      return state;
    }  
  },
  extraReducers: { }
})

export const { setClientData } = counterSlice.actions;
export default counterSlice.reducer;