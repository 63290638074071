const apiConstants = {
    GET_SIDE_MENUS: '/dynamic-module/menus',
    GET_DYNAMIC_MODULE_DATATABLE: '/dynamic-module/datatable',

    //Draws
    NEW_DRAWS: '/draw-management',
    DATA_TABLE_DRAW: '/draw-management/datatable/list',
    SELECT_BOX_DRAW: '/draw-management/select-box/list',

    //Price
    NEW_PRICE: '/price-management',
    DATA_TABLE_PRICE: '/price-management/datatable/list',

    //Pools
    NEW_POOL: '/pool-management',
    DATA_TABLE_POOL: '/pool-management/datatable/list',
    UPLOAD_POOL_MEMBERS: '/pool-management/upload/members',

    //Memberships
    DATA_TABLE_MEMBERSHIPS: '/members/membership/datatable',
    MEMBERS_DETAILS: '/members/membership/details',
    NEW_MEMBERSHIP_REGISTRATION: '/members/new/registration',

    //Shares
    DATA_TABLE_SHARES: '/members/shares/list/datatable',
    PRINT_SHARE: '/members/shares/generate/xlsx',

    //Password change
    PASSWORD_CHANGE_REQUEST: '/settings/password/reset',
    DATA_TABLE_USERS: '/users'



}

export default apiConstants;