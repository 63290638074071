import DashboardIcon from '@mui/icons-material/Dashboard';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { LogoutOutlined } from '@mui/icons-material';
import { destroyAuth } from '../Core/Services/Auth';


const menus = [
    {
        label: "Membership",
        path: "/memberships",
        icon: <DashboardIcon/>,
        access: ['all']
    },
    {
        label: "Shares",
        path: "/shares",
        icon: <DashboardIcon/>,
        access: ['all']
    },
    {
        label: "Event Registration",
        path: "/event-registration",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Registration",
        path: "/registration",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Masters / Broadband",
        // isHide: true,
        path: "/dynamic/broadband_master",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Masters / KCCL",
        path: "/dynamic/kccl_folio_master",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Masters / KCBL",
        path: "/dynamic/kcbl_share_master",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Masters / Magic IDS",
        path: "/dynamic/magic_ids_master",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Masters / CIDCO",
        path: "/dynamic/cdco_member_master",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Masters / KVBL",
        path: "/dynamic/kvbl_master",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        type: "section",
        label: "Reports",
        path: "#",
        icon: "",
        access: ['all']
    },
    {
        label: "KCCL",
        path: "/report/kccl",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "KCBL",
        path: "/report/kcbl",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "CDCO",
        path: "/report/cdco",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Broadband",
        path: "/report/broadband",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        label: "Magic IDs",
        path: "/report/magic-ids",
        icon: <DashboardIcon/>,
        access: ['admin']
    },
    {
        type: "section",
        label: "Settings",
        path: "#",
        icon: "",
        access: ['all']
    },
    {
        label: "Users",
        path: "/users",
        icon: <VpnKeyIcon/>,
        access: ['admin']
    },
    {
        label: "Change Password",
        path: "/settings/users/password",
        icon: <VpnKeyIcon/>,
        access: ['all']
    },
    {
        label: "Logout",
        path: "#",
        icon: <LogoutOutlined/>,
        access: ['all'],
        onClick: ({ redirectTo }) => {
            destroyAuth(); 
            redirectTo('/login')
        }
    }
];

export default menus;