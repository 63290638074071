const Routes = [
    {
        name: "KCCL",
        path: ["/report/kccl"],
        elementPath: "Kccl",
        prefix: "/admin",
        auth: true
    },
    {
        name: "KCBL",
        path: ["/report/kcbl"],
        elementPath: "Kcbl",
        prefix: "/admin",
        auth: true
    },
    {
        name: "CDCO",
        path: ["/report/cdco"],
        elementPath: "Cdco",
        prefix: "/admin",
        auth: true
    },
    {
        name: "BROADBAND",
        path: ["/report/broadband"],
        elementPath: "Broadband",
        prefix: "/admin",
        auth: true
    },
    {
        name: "Magic IDs",
        path: ["/report/magic-ids"],
        elementPath: "MagicIds",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;