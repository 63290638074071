const Routes = [
    {
        name: "Event Registration",
        path: ["/event-registration"],
        elementPath: "index",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;