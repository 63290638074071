const Routes = [
    {
        name: "Dashboard",
        path: ["/dashboard"],
        elementPath: "Dashboard",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;