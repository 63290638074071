const Routes = [
    {
        name: "Change Password",
        path: ["/settings/users/password"],
        elementPath: "Users/ChangePassword",
        prefix: "/admin",
        auth: true
    }
]

export default Routes;