const Routes = [
    {
        name: "Users",
        path: ["/users"],
        elementPath: "Users",
        prefix: "/admin",
        auth: true
    },
]

export default Routes;